<template>
  <div class="inline-flex bg-lightest">
    <button
      class="w-32 h-32 desk:w-40 desk:h-40 flex items-center justify-center group/item"
      :class="{'pointer-events-none': pointCost < 0 || qty < 1 || cartStore.isAlternativeLoading }"
      :disabled="pointCost < 0 || qty < 1 || cartStore.isAlternativeLoading"
      @click="remove"
    >
      <img
        src="/icons/minus.svg"
        class="
        w-16 h-16 outline outline-8 outline-transparent select-none transition-all
        group-hover/item:bg-brandLight group-hover/item:outline-brandLight"
        :class="{
          'opacity-50': qty < 1,
        }"
        alt=""
      >
    </button>
    <div class="miniNumber w-32 h-32 desk:h-40 type-xs-medium desk:type-sm-medium py-6 desk:py-8">
      <transition name="fade">
        <Tooltip
          v-if="noMorePoints"
          :text="$t('pointshop.notEnough')"
          :permanent="true"
          class="text-criticalDark"
        >
          <div class="absolute left-half" />
        </Tooltip>
      </transition>
      <LoadingIcon v-if="thisLoading" />
      <span v-else>{{ qty }}</span>
    </div>
    <button
      class="w-32 h-32 desk:w-40 desk:h-40 flex items-center justify-center group/item"
      :class="{
        'fakeDisabled noHover': pointCost < 0 || (totalCostNext + cartStore.pointsUsed) > cartStore.pointsAvailable || qty + 1 > inStock || cartStore.isAlternativeLoading
      }"
      @click="add"
    >
      <img
        src="/icons/plus.svg"
        class="
        w-16 h-16 outline outline-8 outline-transparent select-none transition-all
        group-hover/item:bg-brandLight group-hover/item:outline-brandLight"
        alt=""
      >
    </button>
  </div>
</template>

<script setup lang="ts">
import { UserPrice } from '~/store/user';
import { useCartStore } from '~/store/cart';
import useApiFetch from '~/composeables/useApiFetch';
import { ProductModel } from '~/models/product';
import { CartApiResponse } from '~/constants/types/norce';
import LoadingIcon from '~/components/generic/LoadingIcon.vue';
import Tooltip from '~/components/body/Tooltip.vue';
/*
import DebugOutput from '~/components/debug/DebugOutput.vue';
*/
const cartStore = useCartStore();
const route = useRoute();
const props = defineProps<{
  userPrice: UserPrice,
  product: ProductModel
}>();
const { apiPost, handleApiError } = useApiFetch();
const qty = ref(0);
const timer = ref<any>(null);
const basketId = route.params.basketId;
const thisLoading = ref(false);
const noMorePoints = ref(false);

const pointCost = computed(()=> {
  if (props.userPrice.status === 'ok' && props.userPrice.price?.points) {
    return props.userPrice.price.points;
  }
  return -1;
});
const totalCostNext = computed(()=> {
  return pointCost.value;
});

const inStock = computed(()=> {
  const variant = props.product.mainVariant;
  return variant?.stockStatus?.inStock || 0;
});

const add = () => {
  if (pointCost.value < 0
    || (totalCostNext.value + cartStore.pointsUsed) > cartStore.pointsAvailable
    || qty.value + 1 > inStock.value
    || cartStore.isAlternativeLoading
  ) {
    //not enougt points
    noMorePoints.value = true;
    setTimeout(()=> {
      noMorePoints.value = false;
    }, 2000);

  } else {
    qty.value++;
    cartStore.changePointsUsed(pointCost.value);
    clearTimeout(timer.value);
    timer.value = setTimeout(() => {
      sendUpdate();
    }, 350);
  }
};
const remove = () => {
  qty.value--;
  cartStore.changePointsUsed(0 - pointCost.value);
  clearTimeout(timer.value);
  timer.value = setTimeout(()=> {sendUpdate();}, 350);
};

const sendUpdate = async() => {
  cartStore.isAlternativeLoading = true;
  thisLoading.value = true;
  const res = await apiPost<CartApiResponse>(
    `/basket/${basketId}/points-products`,
    {
      partNo: props.product.partNo,
      quantity: qty.value,
    },
    null,
    false
  );
  if (res) {
    cartStore.alternativeCart = res;
    cartStore.pointsAvailable = res.summary.points;
    cartStore.pointsUsed = res.summary.usedPoints;
  } else {
    handleApiError();
  }
  cartStore.isAlternativeLoading = false;
  thisLoading.value = false;
};

/**
 * Watch for store changes, example "clear all" button
 */
watch(()=>cartStore.pointStoreItems, (newVal) => {
  const thisProduct = newVal.find((f)=> f.partNo === props.product.partNo);
  if (thisProduct) {
    qty.value = thisProduct.quantity;
  } else if (qty.value > 0) {
    qty.value = 0;
  }
});

</script>

<style scoped lang="postcss">
</style>
